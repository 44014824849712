import { push } from "connected-react-router";
import { SocialError } from "../../core/models/common/socialError";
// import { SocialProviderTypes } from "../../core/socialProviderTypes";
// import { IAuthorizeService } from "../../core/services/authorize";
import { User } from "../../core/models/users";
import { globalActions } from "./index";
// import { provider } from "../../groupApp";
import { AuthorizeActionType } from "../../constants/authorizeActionType";
import { GlobalActionType } from "../../constants/globalActionType";
import { removeCookieValue } from "../../utils/cookieStorage";
import { CommonConstants } from "../../constants/common";
import { getlocalStorageValue } from "../../utils/localStorage";
import { AuthorizeService } from "../../api/services/authorize";

export const login = (user: any) => {
  return {
    type: AuthorizeActionType.LOGIN,
    payload: { authed: true, uid: user.id, user }
  };
};

export const logout = () => {
  return { type: AuthorizeActionType.LOGOUT };
};

export const signup = (user: User) => {
  return {
    type: AuthorizeActionType.SIGNUP,
    payload: { ...user, user }
  };
};

export const updateUser = (user: User) => {
  return {
    type: AuthorizeActionType.UPDATE_USER,
    payload: { ...user, user }
  };
};

export const updatePassword = () => {
  return { type: AuthorizeActionType.UPDATE_PASSWORD };
};

// export const authorizeService: IAuthorizeService = provider.get<
//   IAuthorizeService
// >(SocialProviderTypes.AuthorizeService);

export const dbLogin = (email: string, password: string, isInvite: boolean, ip: any, ua: any, session_token: string, group_id: number, planUrl?: any, isJoin?: any) => {
  return (dispatch: any, getState: any) => {
    return AuthorizeService.getInstance()
      .login(email, password, ip, ua, session_token, group_id)
      .then((result: any) => {
        dispatch(globalActions.clearMessage());
        dispatch(globalActions.errorMessage());
        if (result && result.id) {
          dispatch(login(result));
        }
        const nextLink = getlocalStorageValue("next_link") && getlocalStorageValue("next_link").value;

        if (nextLink) {
          localStorage.removeItem("next_link");
          dispatch(push(nextLink));
          return;
        }

        if (isInvite) {
          dispatch(push(`/plan-subscription?invite=true${planUrl ? `&utm_content=${planUrl}` : ""}`));
        } else if (isJoin) {
          dispatch(push(`/?join=true${planUrl ? `&utm_content=${planUrl}` : ""}`));
        }
        else {
          dispatch(push(`/`));
        }
      })
      .catch((err: any) => {
        dispatch(globalActions.showMessage(err.data));
      });
  };
};

export const dbOAuthLogin = (response: any, isInvite: boolean, ip: any, ua: any, planUrl?: any, isJoin?: any) => {
  return (dispatch: any, getState: any) => {
    return AuthorizeService.getInstance()
      .getAuthorizableUser(response)
      .then((result: any) => {
        dispatch(globalActions.clearMessage());
        dispatch(globalActions.errorMessage());
        if (result && result.id) {
          dispatch(login(result));
        }
        const nextLink = getlocalStorageValue("next_link") && getlocalStorageValue("next_link").value;
        if (nextLink) {
          localStorage.removeItem("next_link");
          dispatch(push(nextLink));
          return;
        }

        if (isInvite) {
          // dispatch(push(`/plan-subscription?invite=true${planUrl ? `&utm_content=${planUrl}` : ""}`));
          dispatch(push(`/`));
        } else if (isJoin) {
          dispatch(push(`/?join=true${planUrl ? `&utm_content=${planUrl}` : ""}`));
        }
        else {
          dispatch(push(`/`));
        }
      })
      .catch((err: any) => {
        dispatch(globalActions.showMessage(err.data));
      });
  };
};


export const dbForgotPassword = (email: string, session_token: string, groupId?: any) => {
  return (dispatch: any, getState: any) => {
    return AuthorizeService.getInstance().forgotPassword(email, session_token, groupId).then(
      (result: any) => {
        dispatch(push("/reset-password", { email }));
      },
      (error: SocialError) => {
        dispatch(globalActions.hideTopLoading());
        dispatch(globalActions.showMessage(error.code));
      }
    );
  };
};

export const dbLogout = () => {
  return (dispatch: any, getState: any) => {
    removeCookieValue(CommonConstants.USER_KEY);
    removeCookieValue(CommonConstants.USER_TOKEN);
    removeCookieValue("lastMessagingView");
    localStorage.removeItem("isShowChatroom");
    localStorage.removeItem("user_token_expires");
    localStorage.removeItem("next_link");
    localStorage.removeItem("lecture-sidebar");
    localStorage.removeItem("sidebar");

    // localStorage.clear();
    const expires = new Date()
    localStorage.setItem(`user_logged_out`, expires.toString());
    setTimeout(() => {
      dispatch(logout());
      localStorage.clear();
    }, 1000)
    dispatch({ type: GlobalActionType.HANDLE_CLEAR_STORE });
  };
};

export const dbSignup = (user: User, isInvite: boolean, utm_content?: any, planUrl?: any, promo_code?: any) => {
  return (dispatch: any, getState: any) => {
    const newUser = { ...user };
    return AuthorizeService.getInstance()
      .registerUser(newUser)
      .then((result: any) => {
        dispatch(
          signup({
            ...user,
            id: result._uid
          })
        );
        const nextLink = getlocalStorageValue("next_link") && getlocalStorageValue("next_link").value;
        if (nextLink) {
          localStorage.removeItem("next_link");
          dispatch(push(nextLink));
          return;
        }

        if (isInvite) {
          dispatch(push(`/plan-subscription?invite=true${planUrl ? `&utm_content=${planUrl}` : ""}`));
        } else {
          dispatch(push(`/verify-account${utm_content ? "?utm_content=" + utm_content : ""}${planUrl ? `?utm_content=${planUrl}` : ""}${promo_code ? (utm_content || planUrl) ? `&promo_code=${promo_code}` : `?promo_code=${promo_code}` : ``}`));
        }
      })
      .catch((error: SocialError) => {
        dispatch(globalActions.hideTopLoading());
        dispatch(globalActions.showMessage(error.message));
      });
  };
};

export const dbOAutSignup = (oauthResult: any, isInvite: boolean, utm_content?: any, planUrl?: any, promo_code?: any) => {
  return (dispatch: any, getState: any) => {
    return AuthorizeService.getInstance()
      .getRegisteringUser(oauthResult)
      .then((result: any) => {
        dispatch(
          signup({
            full_name: oauthResult.data.user.full_name,
            avatar: oauthResult.data.user.avatar,
            creationDate: oauthResult.data.user.updated_at,
            id: result._uid,
            session_token: oauthResult.data.user.session_token
          })
        );
        const nextLink = getlocalStorageValue("next_link") && getlocalStorageValue("next_link").value;
        if (nextLink) {
          localStorage.removeItem("next_link");
          dispatch(push(nextLink));
          return;
        }

        if (isInvite) {
          dispatch(push(`/plan-subscription?invite=true${planUrl ? `&utm_content=${planUrl}` : ""}`));
        } else {
          dispatch(push(`/verify-account${utm_content ? "?utm_content=" + utm_content : ""}${planUrl ? `?utm_content=${planUrl}` : ""}${promo_code ? (utm_content || planUrl) ? `&promo_code=${promo_code}` : `?promo_code=${promo_code}` : ``}`));
        }
      })
      .catch((error: SocialError) => {
        dispatch(globalActions.hideTopLoading());
        dispatch(globalActions.showMessage(error.message));
      });
  };
};

