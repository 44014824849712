export enum CommonConstants {
  USER_KEY = "user",
  USER_TOKEN = "token"
}

export const IMAGE_COMPRESSION_OPTIONS = {
  maxSizeMB: 10,
  maxWidthOrHeight: 1920,
  useWebWorker: true
};

export const APP_LINKS = {
  LOGIN: "/signin"
}

export const INVITE_CONFIG_PARAM_KEY = "jn_cng";
export const INVITED_EMAIL_PARAM_KEY = "e";
export const POWERED_BY_GROUAPP_LINK = "https://www.group.app/?utm_source=GroupApp+communities&utm_medium=Log+in+screen&utm_campaign=Powered+by+GroupApp+log+in"
export const TURNSTILE_SITE_KEY = "0x4AAAAAAA-0s47WPG9QBjUB";